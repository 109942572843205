.linksBlock{
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;
    flex-grow: 0;
    justify-content: center;
    gap: 50px;
}
.linksBlock a{
    flex-basis: 25%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 15px;
    padding: 25px;
    transition: all 0.2s linear;
}
.link{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.link .imageBlock img{
    width: 80%;

}
.title{
    color: var(--black);
    font-weight: 700;
    font-size: 16px;
    margin-top: 25px;
    transition: all 0.2s linear;
}
.linksBlock a:hover{
    background-color:var(--main-color);
    transform: scale(1.05);
}
.linksBlock a:hover  .title{
    color: var(--white);
}

@media screen and (max-width: 770px){
    .linksBlock a{
        flex-basis: 40%;
    }
}

@media screen and (max-width: 530px){
    .linksBlock{
        gap: 25px;
    }
    .linksBlock a{
        flex-basis: 45%;
    }
    .title{
        font-size: 10px;
    }
}
@media screen and (max-width: 440px){
.linksBlock{
    gap: 10px;
}
    .title{
        font-size: 7px;
    }
}