.templateContent{
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;
    gap:50px;
    align-items: center;
}
.templateContent > div{
    flex-basis: 47%;
}
.templateContent .imageBlock img{
    object-fit: cover;
    max-height: 700px;
    width: 100%;
    border-radius: 10px;
}
.infoTitle{
    font-size: 36px;
    font-weight: 700;
    color: var(--black);
    margin-bottom: 25px;
    letter-spacing: 1px;
}
.info{
    margin-bottom: 30px;
}
.info p {
    margin: 5px 0;
    text-align: justify;
    letter-spacing: 1px;
    color: var(--second-color);
}
.description{
    color: var(--black);
    margin-bottom: 50px;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 13px;
}
.description p{
    color: var(--main-color);
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 16px;
    letter-spacing: 1px;
}
.item{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.item svg{
    color: #006f93;
    font-size: 28px;
    margin-right: 15px;
}

@media screen and (max-width: 920px){
    .templateContent > div{
        flex-basis: 45%;
    }
    .infoTitle{
        font-size: 28px;
    }
    .info p{
        font-size: 12px;
    }
    .description{
        font-size: 10px;
    }
}
@media screen and (max-width: 780px){
    .infoTitle{
        font-size: 24px;
    }
    .info p {
        font-size: 10px;
    }
    .description{
        font-size: 8px;
    }
    .item{
        margin-bottom: 5px;
    }
    .item svg{
        font-size: 20px;
    }
}
@media screen and (max-width: 620px){
    .templateContent{
        gap: 20px;
    }
    .infoTitle{
        font-size: 12px;
        margin-bottom: 10px;
    }
    .info{
        margin-bottom: 10px;
    }
    .info p {
        font-size: 8px;
    }
    .description{
        font-size: 7px;
    }
    .item svg{
        font-size: 16px;
        margin-right: 5px;
    }
}
@media screen and (max-width: 530px){

    .templateContent{
        flex-direction: column;
    }
    .InfoBlock{
        width: 100%;
        text-align: left;
    }
    .info{
        margin-bottom: 25px;
    }
    .infoTitle{
        font-size: 28px;
        margin-bottom: 25px;
    }
    .description{
        font-size: 10px;
        margin-bottom: 0;
    }
    .item svg{
        font-size: 24px;
    }
}
@media screen and (max-width: 470px){
    .infoTitle{
        font-size: 22px;
    }
}