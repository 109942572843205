footer{
    margin-top: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 100%;
    background: var(--second-color);
    padding: 25px;
}
.footerWrapper{
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    flex-grow: 0;
    color: var(--white);
    gap: 40px;
}
.footerWrapper > div{
    flex-basis: 20%;
}
.logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.logo img{
    width: 100px;
    height: 100px;
}
.footerTitle{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 25px;
    color: var(--main-color);
}
.social{
    display: flex;
    padding-top: 20px;
    gap: 10px;
}
.social div{
    width: 35px;
    height: 35px;
}
.socialItem a{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    background: var(--white);
    border-radius: 50%;
    margin-right: 10px;transition: all 0.2s linear;
    cursor: pointer;
}
.socialItem a:hover{
    background: var(--main-color);
    color: var(--white);
}

.lists a div{
    font-size: 12px;
    margin-bottom: 10px;
}
.lists a{
    color: var(--white);
}
.lists a:hover{
    color: #1e1e1e;
}
.infoItem{
    display: flex;
    margin-bottom: 5px;
    min-width: 100%;
    flex-shrink: 0;
}

 .infoItem div svg{
    font-size: 18px;
    color: var(--white);
    margin-right: 10px;
}
 .infoItem h3{
     font-size: 14px;
 }
 .infoItem h4{
     color: #d3d3d3;
     font-size: 12px;
 }
 @media screen and (max-width: 1000px){
     .footerTitle{
         font-size: 16px;
     }
 }
 @media screen and (max-width: 795px){

     .footerWrapper > div:first-child{
         flex-basis: 100%;
         text-align: center;
     }
     .social{
         justify-content: center;
     }

 }
@media screen and (max-width: 595px){
    .footerWrapper{
        gap: 20px;
    }
    .footerTitle{
        margin-bottom: 10px;
        font-size: 18px;
    }
    .lists a {
        padding: 0;
    }
    .lists a div{
        margin: 0;
    }
    .footerWrapper > div{
        flex-basis: 45%;
        text-align: left;
    }
    .footerWrapper > div:first-child{
        text-align: center;
    }
    .footerWrapper > div:last-child{
        flex-basis: 100%;
    }
    .footerWrapper > div:last-child  .lists{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

}