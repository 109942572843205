.informationBlock{
    background-color: var(--second-color);
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px 25px;
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
}
.contacts{
    color:#fff;
}
.contacts,.links{
    display: flex;
    align-items: center;
}
.contacts a{
    color: white;
    transition: all .2s;
}
.contacts a:hover{
    color: var(--main-color);
}
.contacts div{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.contacts div span{
    display: flex;
    align-items: center;
    margin-right: 3px;
}
.language{
    display: flex;
}
.language img{
    width: 40px;
    height: 22px;
    cursor: pointer;
}

@media screen and (max-width: 576px){
    .contacts div{
        font-size: 12px;
    }
}
@media screen and (max-width: 430px){
    .contacts div{
        font-size: 8px;
    }
    .language img{
        width: 25px;
        height: 17px;
        cursor: pointer;
    }
}