.sectionTitle{
    position: relative;
    font-size: 22px;
    text-align: center;
    color: #333;
    padding: 25px 0;
    z-index: 1;
    letter-spacing: 2px;
    text-transform: capitalize;
}
.sectionTitle:after{
    content:"";
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: var(--main-color);
    bottom: 10px;
    left: 50%;
    transform: translate(-50% ,-50%);
    z-index: -1;
}

@media screen and (max-width: 830px){
    .sectionTitle{
        font-size: 16px;
    }
}
@media screen and (max-width: 630px){
    .sectionTitle{
        font-size: 14px;
    }
}