.headerWrapper{
    height: 600px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.background{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: rgba(0,0,0,0.2);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.headerContentBlock{
    padding-left: 25px;
    letter-spacing: 2px;
    margin-bottom: 8%;
}
.headerContentBlock h4{
    color: var(--white);
    font-size: 20px;
}
.headerContentBlock h1{
    font-size: 56px;
    color: var(--white);
    padding: 5px 0;

}
.headerContentBlock p{
    color: #fff;
    margin-top: 15px;
    letter-spacing: 2px;
}
@media screen and (max-width: 640px){
    .headerWrapper{
        height: 450px;
    }
    .headerContentBlock h1{
        max-width: 100%;
        font-size: 32px;
    }

}
@media screen and (max-width: 420px){
    .headerWrapper{
        max-height: 400px;
    }
    .headerContentBlock h1{
        max-width: 100%;
        font-size: 24px;
    }
    .headerContentBlock p{
        font-size: 12px;
    }
}
@media screen and (max-width: 365px){

    .headerContentBlock h1{
        font-size: 18px;
    }
    .headerContentBlock p{
        font-size: 10px;
    }
}

/**/
.headerWrapper img {
    width: 100%;
    height: 100%;

}
.headerWrapper{
    position: relative;
}
.headerContentBlock{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 12;
}
.background{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}