.partnerContent{
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;
    gap:50px;
    align-items: center;

}
.partnerContent div{
    flex-basis: 47%;
}
.partnerContent .imageBlock img{
    object-fit: cover;
    max-height: 700px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.infoTitle{
    font-size: 36px;
    font-weight: 700;
    color: var(--black);
    margin-bottom: 25px;
}
.description{
    color: var(--grey-color);
    margin-bottom: 50px;
    letter-spacing: 1px;
}
.trust{
    color: var(--main-color);
    letter-spacing: 1px;
    margin-bottom: 15px;
}

.partner{
    font-size: 13px;
    letter-spacing: 1px;
    color: var(--black);
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
}
.partner span {
    margin-right: 9px;
    color: var(--main-color);
    font-size: 20px;
}
.partner b{

}

@media screen and (max-width: 890px){
    .infoTitle{
        font-size: 36px;
    }
    .description,.trust{
        font-size: 14px;
    }
    .partnerContent{
        justify-content: center;
    }
    .partnerContent div:first-child{
        flex-basis: 55%;
    }
    .partnerContent div:last-child{
        flex-basis: 37%;
    }
}
@media screen and (max-width: 770px){
    .partnerContent div:first-child,.partnerContent div:last-child{
        flex-basis: 80%;
    }
    .partnersList{
        margin-top: 10px;
    }
}

@media screen and (max-width: 500px){
    .partner{
        flex-basis: 35%;
    }
    .partner b{
        font-size: 10px;
    }
}