.calculatorBlock{
    background-color: var(--main-color);
    margin-top: 25px;
    padding: 25px 0 1px 0;
    border-radius: 10px;
}
.fieldsBlock{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 25px 0;
}
.fieldsBlock h3{
    color: var(--white);
}
.fieldBlock{
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.fieldBlock .monthBlock{
    width: 100%;
}
.calculatorBlock input{
    margin-top: 10px;
    background-color: var(--white);
    height: 44px;
    padding: 5px 14px;
    border: 1px solid #dedede;
    border-radius: 5px;
    outline: none;
    font-size: 18px;
    width: 100%;
}
.calculatorBlock input:focus{
    border: 1px solid var(--second-color);
}
.calculatorBlock input::placeholder{
    font-size: 12px;
}

.err{
    color: rgb(255, 255, 255);
    text-align: center;
    margin-top: 5px;
}


.showResult{
    max-width: 150px;
    text-align: center;
    border-radius: 10px;
    background-color: var(--second-color);
    color: var(--white);
    padding: 10px;
    margin: 25px auto;
    cursor: pointer;
    transition: all 0.2s linear;
    letter-spacing: 1px;
}
.showResult:hover{
    background-color: var(--black);
    color: var(--white);
}
.table{
    margin-top: 10px;
    width: 100%;
    color: #313131;
}
.table tr td{
    padding: 7px;
    border:1px solid #d5d5d5;
    font-weight: 500;
    letter-spacing: 1px;
}
.table tr:last-child td{
    border: none;
    font-weight: 700;
}
.table tr th{
    padding: 7px;
    border:1px solid #d5d5d5;
    letter-spacing: 1px;
}
.table tr:first-child th:first-child{
    border-top-left-radius: 10px;
}
.table tr:first-child th:last-child{
    border-top-right-radius: 10px;
}
.table tr td span{
    font-weight: 700;
    color: var(--black);
}

@media screen and (max-width: 850px){
    .fieldBlock h3{
        font-size: 14px;
    }
    .err{
        font-size: 12px;
    }
    .calculatorBlock input{
        font-size: 14px;
    }
    .calculatorBlock input::placeholder{
        font-size: 10px;
    }
}
@media screen and (max-width: 650px){
    .fieldBlock {
        flex-basis: 75%;
        margin: 10px 0;
    }
    .fieldBlock h3{
        font-size: 18px;
    }
    .table tr td, .table tr th{
        font-size: 14px;
    }
}
@media screen and (max-width: 500px){
    .table tr td, .table tr th{
        font-size: 10px;
    }
    .calculatorBlock{
        padding: 5px;
    }
    .fieldBlock{
        flex-basis: 55%;
    }
    .fieldBlock h3{
        font-size: 14px;
    }
    .fieldBlock input{
        font-size: 12px;
        padding: 5px;
    }
}