.menuBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(250,250,250,0.9);
    margin: 0 auto;
    width: 100%;
}
.logo{
    padding: 20px 25px;
}
.logo a{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}
.logo a img{
    margin-right: 5px;
    width: 45px;
    height: 45px;
}

.menu nav{
    display: flex;
    width: 100%;
    margin-right: 25px;
}
.menu nav div.menuItems{
    display: flex;
}
.menu nav .item{
    display: flex;
    align-items: center;
    margin-left: 30px;
    color: var(--second-color);
    -webkit-transition: max-height 200ms;
    -webkit-transition: -webkit-max-height 200ms;
    transition: all 200ms;
    cursor: pointer;
    padding: 20px 0;

}
.subMenuOpenArrow{
    margin-left: 5px;
}
.itemWrapper {
    position: relative;
}

.item span{
    display: flex;
    align-items: center;
}
.item svg{
    transform: rotate(90deg);
}
.itemWrapper:hover > .subMenu{
    display: block;

}
.subMenu{
    position: absolute;
    left: 0;
    top: 61px;
    display: none;
    background-color: rgba(250,250,250,1);
    z-index: 9990;
}
.subMenu div{
    padding: 10px ;
    min-width: 150px;
    white-space: nowrap;
}
.subMenu div:hover{
    background-color: var(--second-color);
    color: #fff;
}
.menu nav .itemWrapper:hover > span{
    color: var(--main-color);
}
.burgerMenu{
    color: var(--second-color);
    display: none;
    font-size: 24px;
    -webkit-transition: max-height 200ms;
    -webkit-transition: -webkit-max-height 200ms;
    transition: all 200ms;
}



@media screen and (max-width:1000px)  {
    .menu nav .item{
        font-size: 14px;
    }
    .subMenu{
        top:59px;
        font-size: 14px;
    }
}
@media screen and (max-width:880px)  {
    .menu nav .item{
        font-size: 12px;

    }
    .subMenu{
        top:56px;
        font-size: 12px;
    }
}
@media screen and (max-width:700px)  {
    .logo a{

        font-size: 20px;
    }
    .logo a img{

    }
    .menuBlock {
        position: relative;

    }
    .subMenu{
        top:56px
    }
    .burgerMenu{
        display: inline-block;
    }
    .menuOpened{
        transform: rotate(90deg);
    }

    .menu nav div.menuItems{
        position: absolute;
        left: 0;
        top: 85px;
        width: 100%;
        background-color: var(--second-color);
        display: flex;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 200ms;
        -webkit-transition: -webkit-max-height 200ms;
        transition: all 0.2s;
        z-index: 9999;
    }
    
    .menu nav div.menuItems .item{
        position: relative;
        color: var(--white);
        display: flex;
        letter-spacing: 1px;
        justify-content: space-between;
        margin-right: 30px;
    }
    .subMenuOpenArrow{
        transform: rotate(-90deg);
        transition: all 200ms;
    }
    .openedMobileMenu .subMenuOpenArrow{
        transform: rotate(0);
    }

    .menu nav div.menuItems .itemWrapper{
        border-bottom: 1px solid #ddd;
    }
    .menu nav div.menuItems .itemWrapper:last-child{
        border-bottom: none;
    }
    .subMenu{
        margin-left: 30px;
        position: unset;
        display: block;
        background-color: transparent;
        color: #fff;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 200ms;
        -webkit-transition: -webkit-max-height 200ms;
        transition: all 200ms;
    }

    .menu nav div.burgerMenuActive{
        max-height: 1200px;
    }
    .subMenu a{
        color: #eee;
        letter-spacing: 1px;
    }


    .openedMobileMenu .subMenu{
        max-height: 500px;
    }
}

@media screen and (max-width: 576px){
    .menu nav div.menuItems{
        top: 75px;
    }
    .logo a img{
        width: 35px;
        height: 35px;
    }
    .logo a{
        font-size: 16px;
    }
}