.calculateBlock{
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cleanRegisteredBlock{
    display: flex;
    width: 100%;
    gap: 10px;
}
.cleanRegisteredBlock div{
    margin-top: 15px;
    padding: 15px 0;
    border-radius: 10px;
    flex-basis: 50%;
    text-align: center;
    background-color: var(--second-color);
    color: #fff;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.calculateBlock div.selectedMethod{
    background-color: var(--main-color);
    box-shadow: var(--main-color) 0px 5px 15px;
    transform: scale(1.02);
}

/* Vcharox */
.vcharoxBlock{
    display: flex;
    width: 100%;
    gap: 10px;
}
.vcharoxBlock div{
    padding: 15px;
    border-radius: 10px;
    flex-basis: 50%;
    text-align: center;
    background-color: var(--second-color);
    color: #fff;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}
/* Enter Salary */
.enterSalary{
    width: 100%;
    padding-top: 15px;
}
.enterSalary input{
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    outline: none;
    border:1px solid var(--second-color);
    appearance: none;
     -moz-appearance: textfield;
    font-size: 40px;
    color: var(--black);
}
.enterSalary input::placeholder{
    font-size: 22px;
    color: var(--grey-color);
    letter-spacing: 1px;
}

.enterSalary input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
/* resultBlock */
.resultBlock{
    display: flex;
    width: 100%;
}
.resultBlock:last-child div:first-child{
    border-bottom-left-radius: 10px;
}
.resultBlock:last-child div:last-child{
    border-bottom-right-radius: 10px;
}
.resultBlock div:first-child{
    flex-basis: 40%;
    padding: 15px;
    margin-top: 5px;
    background-color: antiquewhite;
    font-size: 20px;
    color: #666;
}
.resultBlock div:last-child{
    flex-basis: 60%;
    padding: 15px;
    margin-top: 5px;
    background-color: #dfdfdf;
    text-align: right;
    font-size: 24px;
    font-weight: 700;
    color: var(--black);
}
.resultWrapper{
    margin-top: 10px;
    width: 100%;
}
.calculateBlock p{
    font-size: 13px;
    padding: 10px 0 7px 0;
    margin: 10px 0 5px 0;
    color: var(--second-color);
}
.salaryInfo span{
    font-weight: 700;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 630px){
    .calculateBlock{
        padding: 25px 0 0 0;
    }
   .resultBlock div:first-child{
       font-size: 12px;
   }
    .enterSalary input{
        font-size: 24px;
    }
    .enterSalary input::placeholder{
        font-size: 18px;
    }
}

@media screen and (max-width: 500px){
    .resultBlock div:first-child{
        font-size: 9px;
    }
    .cleanRegisteredBlock,.vcharoxBlock{
        font-size: 12px;
    }
    .salaryInfo p{
        font-size: 10px;
    }
    .resultBlock div:last-child{
        font-size: 12px;
    }
    .enterSalary input{
        font-size: 20px;
    }
    .enterSalary input::placeholder{
        font-size: 16px;
    }

}

@media screen  and (max-width: 400px){
    .resultBlock div:first-child{
        font-size: 7px;
    }
}