.servicesSubTitle{
    text-align: center;
    font-size: 18px;
    letter-spacing: 2px;
    color: var(--main-color);
    margin-top: 15px;
}

.servicesContent{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    flex-grow: 0;
    gap: 35px;
}

@media screen and (max-width: 1000px){
    .servicesContent a{
        flex-basis: 45%;
    }
}
@media screen and (max-width: 800px){
    .servicesSubTitle{
        font-size: 14px;
    }
}
@media screen and (max-width: 620px){
    .servicesSubTitle{
        font-size: 10px;
    }
    .cardLink{
        padding: 5px;
    }
    .service svg{
        font-size: 36px;
    }
    .cardTitle{
        font-size: 16px;
    }
    .cardDescription{
        font-size: 10px;
    }
}
@media screen and (max-width: 510px){
    .servicesContent a{
        flex-basis: 90%;
    }
    .servicesContent{
        gap:10px
    }
}

