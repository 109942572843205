.sendEmailBlock{
    background-color: #e1f8fb;
    padding: 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.fields{
    display: flex;
    flex-flow: row wrap;
    gap: 25px 10px;
    justify-content: space-between;
    margin-top: 15px;

}
.methodsContent h2 {
    color:var(--black);
    margin-top: 30px;
}
.standardMethod .inputBlock:first-child{
    flex-basis: 100%;
    margin: 0 calc(100% - 12.5);
}
.inputBlock{
    flex-basis: 48.5%;
    position: relative;
}
.excelInputBlock{
    margin-top: 15px;
    flex-basis: 100%;
}
.fieldTitle{
    color: var(--second-color);
    margin-bottom: 5px;
    font-weight: 700;
}

.inputBlock input{
    width: 100%;
    padding: 15px;
    outline: none;
    border:1px solid #ccc;
    border-radius: 5px;
    transition: all .5s;
    letter-spacing: 1px;
}
.errorBlock input{
    border-color: rgb(252, 89, 89);
}
.inputBlock input:focus{
    border-color: var(--second-color);
}
.errorMessage{
    position: absolute;
    top: 10px;
    right: 2px;
}

.buttonBlock{
    text-align: center;
    margin-top: 25px;
}
.buttonBlock button{
    padding: 15px 50px;
    border:none;
    color:#fff;
    border-radius: 5px;
    background-color: var(--second-color);
    cursor: pointer;
    transition: all .5s;
}
.buttonBlock button:hover{
    background-color: var(--main-color);
}
.errorMessage[title]:hover::after {
    content: attr(title);
    position: absolute;
    max-width: 200px;
    width: auto;
    padding: 15px 10px;
    border-radius: 8px;
    bottom: 50%;
    left: 50px;
    background-color: rgb(252, 89, 89);
    color: #fff;
    z-index: 9999;
    font-size: 18px;
    transition: all .5s;
}
/*Popup Styles*/
.popupWrapper div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    background-color: var(--main-color);
    border-radius: 10px;
    z-index: 9999;
    transition: all 3s linear;
}

.popupWrapper div.popupBlock {
    transform: translate(-50%, -50%) scale(1);
}
@media screen and (max-width: 1550px){
    .errorMessage[title]:hover::after{
        left: unset;
        right: 50px;
    }
}
@media screen and (max-width:765px){
    .inputBlock input{
        letter-spacing: 0;
    }
    .inputBlock input::placeholder{
        letter-spacing: 0;
    }

}
@media screen and (max-width:680px){
    .fields{
        gap: 10px 5px;
        justify-content: space-between;
    }
    .inputBlock{
        flex-basis: 49%;
    }
    .errorMessage[title]:hover::after {
        padding: 8px;
        font-size: 10px;
    }
}
@media screen and (max-width:560px){
    div.selectInputBlock{
        flex-basis: 100%;
    }
    .fieldTitle{
        font-size: 12px;
    }
    .inputBlock input{
        font-size: 14px;
    }
    .inputBlock input::placeholder{
        font-size: 10px;
    }
    .buttonBlock button {
        padding: 10px 30px;
    }
    .methodsContent h2{
        font-size: 16px;
    }
}
@media screen and (max-width:450px){

    .inputBlock input{
        font-size: 12px;
    }
    .inputBlock input::placeholder{
        letter-spacing: 0;
        font-size: 8px;
    }
}
@media screen and (max-width:420px){
    .inputBlock input::placeholder{
        font-size: 6px;
    }
}
