.heartbeatWrapper{
    width: 100%;
    height: 250px;
    background-image: url("../../assets/images/headerImg2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 50px;
    border-radius: 10px;
}
.background{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(11,37,65,0.7);
    border-radius: 10px;
}
.background .businessMessage{
    font-size: 36px;
    text-align: center;
    padding: 25px;
    color: #fff;
}
.background .businessMessage span{
    color:var(--main-color);
}

@media screen and (max-width: 700px){
    .heartbeatWrapper{
        height: 200px;
    }
    .background .businessMessage{
        font-size: 24px;
    }
}
@media screen and (max-width: 470px){
    .heartbeatWrapper{
        height: 150px;
        margin: 0;
    }
    .background .businessMessage{
        font-size: 18px;
    }
}